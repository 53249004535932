import "./Footer.css";

const Footer = () => {

    return(
        <div className='footer'>
            &copy; 2023. &nbsp;
            <a
                href=""
               style={{ cursor: "pointer"}}>
                OlusolaWeb
            </a>
        </div>
    )
}

export default Footer;